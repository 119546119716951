import { createGetApi } from 'api-layer';
import { getEnvironment } from 'utils/environment';
import { get } from '../../utils/apiUtils';

const APIS: any = {
  localhost: '/plugin-authorize',
  'recursica-dev': '/api/plugin/authorize',
  recursica: '/api/plugin/authorize',
};
export interface ApiResponse {
  token: string;
  poll: string;
}

function getAuthToken(): Promise<ApiResponse> {
  const url = `${getEnvironment().url}${APIS[getEnvironment().type]}`;
  return get<ApiResponse>(url);
}

export const apiGetAuthToken = createGetApi(getAuthToken, 'getAuthToken.mock.json');
