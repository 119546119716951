/* eslint-disable import/no-named-as-default-member */
import firebase from 'firebase/app';
import 'firebase/functions';
import { getEnvironment } from 'utils/environment';

import { FIREBASE_CONFIG } from './firebaseConfig';

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);

// Initialize functions
const env = getEnvironment();
if (env.type === 'localhost') {
  console.warn('Using Firebase Functions emulator at: ', env.host);
  firebase.functions().useEmulator(env.host, env.port);
}

export { firebaseApp, firebase, FIREBASE_CONFIG };
