import React from 'react';
import { View } from '../View';

export const Home: React.FC<Record<string, never>> = () => {
  return (
    <View title={'Recursica'}>
      <h2>Coming Soon...</h2>
    </View>
  );
};
