import React from 'react';
import { LogoOverlay } from '../components/LogoOverlay/LogoOverlay';
import './styles.scss';

interface ViewProps {
  title?: string;
  logo?: boolean;
}

export const View: React.FC<ViewProps> = ({ children, logo = true }) => {
  return (
    <div className="View">
      {logo && <LogoOverlay />}
      {children}
    </div>
  );
};
