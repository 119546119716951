import { firebase } from 'firebaseApp';
import 'firebase/auth';
import { loadUserPersistance, saveUserPersistance } from './persistUser';
import { PROVIDER_ID } from './authentication';

export interface User extends firebase.User {
  repoProviderId?: string | null;
}

export type VoidCallback = () => void;

export class UserStore {
  initialized: boolean;
  currentUser: User | null;
  loading: boolean;
  subscriptions: Array<VoidCallback>;
  constructor() {
    this.initialized = false;
    this.currentUser = null;
    this.loading = true;
    this.subscriptions = [];
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.firebaseAuthChange = this.firebaseAuthChange.bind(this);
    this.update = this.update.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.initialize = this.initialize.bind(this);
    firebase.auth().onAuthStateChanged(this.firebaseAuthChange);
  }
  initialize(user: firebase.User | null): void {
    if (this.initialized) {
      return;
    }
    this.currentUser = user as User;
    if (!user) {
      saveUserPersistance();
      this.initialized = true;
      this.loading = false;
      return;
    }
    const persist = loadUserPersistance(user.uid);
    this.currentUser.repoProviderId = persist.currentProvider;
    this.initialized = true;
    if (!persist.currentProvider) {
      this.logout();
      return;
    }
    this.loading = false;
    this.update();
  }
  getCurrentUser(): User | null {
    return this.loading ? null : this.currentUser;
  }
  unsubscribe(callback: VoidCallback): void {
    if (!callback) {
      return;
    }
    this.subscriptions = this.subscriptions.filter((item) => item === callback);
  }
  subscribe(callback: VoidCallback): () => void {
    if (!callback) {
      throw new Error('Invalid callback argument');
    }
    const found = this.subscriptions.find((item) => item === callback);
    if (!found) {
      this.subscriptions.push(callback);
    }
    return () => {
      this.unsubscribe(callback);
    };
  }
  firebaseAuthChange(userAuth: firebase.User | null): void {
    if (!this.initialized) {
      this.initialize(userAuth);
      return;
    }
    if (!userAuth) {
      this.logout();
      return;
    }
    this.currentUser = userAuth as User;
    this.loading = true;
  }
  setProvider(providerId?: string | null): void {
    if (!this.loading || !this.currentUser) {
      return;
    }
    const persist = loadUserPersistance(this.currentUser.uid);
    persist.currentProvider = (providerId || null) as PROVIDER_ID;
    saveUserPersistance(persist);
    this.currentUser.repoProviderId = providerId || null;
    this.loading = false;
    this.update();
  }
  update(): void {
    const subscribers = this.subscriptions.slice();
    subscribers.forEach((callback) => {
      callback();
    });
  }
  logout(): void {
    if (!this.currentUser) {
      return;
    }
    this.currentUser = null;
    saveUserPersistance();
    firebase.auth().signOut();
    this.loading = false;
    this.update();
  }
}

export const userStore = new UserStore();
