import React, { SyntheticEvent } from 'react';
import { getEnvironment } from 'utils/environment';
// import { auth } from "auth";
import { View } from '../View';
import './styles.scss';

const GITHUB_AUTHORIZE_URL: any = {
  localhost: '/github-authorize',
  'recursica-dev': '/api/github/authorize',
  recursica: '/api/github/authorize',
};
const GITLAB_AUTHORIZE_URL: any = {
  localhost: '/gitlab-authorize',
  'recursica-dev': '/api/gitlab/authorize',
  recursica: '/api/gitlab/authorize',
};
const LOCAL_AUTHORIZE_URL: any = {
  localhost: '/local-authorize',
  'recursica-dev': '/local-authorize',
  recursica: '/local-authorize',
};

interface LoginProps {
  token: string;
  error: string;
  errorDescription: string;
  result: string;
}

const Login: React.FC<LoginProps> = ({ token, error, errorDescription, result }) => {
  const loginToGithub = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    const redirectUri = `${window.location.origin}/login`;
    const environment = getEnvironment();
    const url = `${environment.url}${
      GITHUB_AUTHORIZE_URL[environment.type]
    }?token=${token}&redirect_uri=${redirectUri}`;
    window.open(url, '_self');
  };
  const loginToGitlab = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    const redirectUri = `${window.location.origin}/login`;
    const environment = getEnvironment();
    const url = `${environment.url}${
      GITLAB_AUTHORIZE_URL[environment.type]
    }?token=${token}&redirect_uri=${redirectUri}`;
    window.open(url, '_self');
  };
  const loginToLocal = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    const redirectUri = `${window.location.origin}/login`;
    const environment = getEnvironment('recursica-dev');
    window.open(
      `${environment.url}${LOCAL_AUTHORIZE_URL[environment.type]}?token=${token}&redirect_uri=${redirectUri}`,
      '_self',
    );
  };
  const renderError = () => {
    return (
      <>
        <h1>{error || 'Login Error'}</h1>
        <h2>{errorDescription || 'An unknown error occurred during login'}</h2>
      </>
    );
  };
  const renderSuccess = () => {
    return (
      <>
        <h1>Login Successful</h1>
        <span>Check Figma to complete the login process. Press Retry if its not showing success</span>
      </>
    );
  };
  const renderContent = () => {
    if (error) {
      return renderError();
    }
    if (result === 'success') {
      return renderSuccess();
    }
    if (!token) {
      return <h2>Login from the Recursica Figma plugin to get started</h2>;
    }
    return (
      <>
        <h1>Select a Repository Provider</h1>
        <ul className={'ProviderList'}>
          <li>
            <button className={'ProviderButton'} id={'LoginToGitlab'} onClick={loginToGitlab}>
              Login to Gitlab
            </button>
          </li>
          <li>
            <button className={'ProviderButton'} id={'LoginToGithub'} onClick={loginToGithub}>
              Login to Github
            </button>
          </li>
          <li>
            <button className={'ProviderButton'} id={'LoginToLocal'} onClick={loginToLocal}>
              Develop on LOCAL
            </button>
          </li>
        </ul>
      </>
    );
  };
  return (
    <View title={'Login'}>
      <div className={'ViewBubble'}>{renderContent()}</div>
    </View>
  );
};

export default Login;
