import { createGetApi } from 'api-layer';
import { get } from 'utils/apiUtils';
import { getEnvironment } from 'utils/environment';

const API = '/plugin-pollAuth';

export interface ApiResponse {
  authorized: boolean;
  uid: string;
  token: string;
  providerId: string;
}

function getPluginPullAuth(token: string | null | undefined): Promise<ApiResponse> {
  const url = `${getEnvironment().url}${API}`;
  return get<ApiResponse>(url, { params: { token } });
}

export const apiGetPluginPullAuth = createGetApi(getPluginPullAuth, 'getPluginPullAuth.mock.json');
