/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useCallback } from 'react';
import { CurrentUserContext } from 'context/CurrentUserContext';
import { User, userStore } from 'auth';

const Auth: React.FC<{}> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(userStore.getCurrentUser());
  const handleUserChange = useCallback(() => {
    setCurrentUser(userStore.getCurrentUser());
  }, []);
  useEffect(() => {
    userStore.subscribe(handleUserChange);
    return () => {
      userStore.unsubscribe(handleUserChange);
    };
  }, []);
  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>;
};

export default Auth;
