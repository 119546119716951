import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import Login from './Login';

const queryConfig = {
  token: StringParam,
  error: StringParam,
  description: StringParam,
  result: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    token: query.token,
    error: query.error,
    errorDescription: query.description,
    result: query.result,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, Login);
