/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Auth } from 'auth';
import { Login } from 'views/Login';
import { Home } from 'views/Home/Home';

function App() {
  return (
    <Auth>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route path={'/login'} component={Login} />
            <Route path={'/'} component={Home} />
          </Switch>
        </QueryParamProvider>
      </Router>
    </Auth>
  );
}

export default App;
