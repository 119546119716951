/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { firebase } from 'firebaseApp';
import 'firebase/auth';
import { userStore, User } from './UserStore';
import { apiGetAuthToken, ApiResponse } from '../api/apiGetAuthToken';
import { apiGetPluginPullAuth } from '../api/apiGetPluginPollAuth';
import { getEnvironment } from 'utils/environment';

const GITHUB_AUTHORIZE_URL: any = {
  localhost: '/github-authorize',
  'recursica-dev': '/api/github/authorize',
  recursica: '/api/github/authorize',
};
const GITLAB_AUTHORIZE_URL: any = {
  localhost: '/gitlab-authorize',
  'recursica-dev': '/api/gitlab/authorize',
  recursica: '/api/gitlab/authorize',
};

export function auth() {
  return firebase.auth();
}
auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export function isAuthenticated(): boolean {
  return !!userStore.getCurrentUser();
}

export enum PROVIDER_ID {
  github = 'github.com',
  gitlab = 'gitlab.com',
  test = 'test',
}

export function startAuthorization(
  providerId: PROVIDER_ID,
  redirectUri: string,
  logoutPrevious = false,
): Promise<ApiResponse> {
  if (isAuthenticated()) {
    // We need to logout
    if (logoutPrevious) {
      userStore.logout();
    } else {
      throw new Error('User is already authorized');
    }
  }
  return apiGetAuthToken().then((response) => {
    let url = '';
    const environment = getEnvironment();
    console.log('Environment: ', environment);
    switch (providerId) {
      case PROVIDER_ID.github:
        url = `${environment.url}${GITHUB_AUTHORIZE_URL[environment.type]}?token=${
          response.token
        }&redirect_uri=${redirectUri}`;
        break;
      case PROVIDER_ID.gitlab:
        url = `${environment.url}${GITLAB_AUTHORIZE_URL[environment.type]}?token=${
          response.token
        }&redirect_uri=${redirectUri}`;
        break;
      default:
        throw new Error('Invalid provider type');
    }
    window.open(url, '_self');
    return response;
  });
}

export function authenticate(token: string): Promise<User | null> {
  if (isAuthenticated()) {
    throw new Error('User is already authenticated');
  }
  let providerId = '';
  return apiGetPluginPullAuth(token)
    .then((response) => {
      if (!response || !response.authorized) {
        throw new Error(`Invalid authentication response: ${response}`);
      }
      providerId = response.providerId;
      return auth().signInWithCustomToken(response.token);
    })
    .then(() => {
      userStore.setProvider(providerId);
      return userStore.getCurrentUser();
    });
}
