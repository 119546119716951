import { PROVIDER_ID } from './authentication';
import { setCookie, getCookie } from '../utils/cookieUtils';

export const CURRENT_VERSION = 1;

export interface SetUserPersistance {
  currentProvider?: PROVIDER_ID | null;
}

export interface UserPersistance {
  uid: string;
  version?: number;
  currentProvider: PROVIDER_ID | null;
}

const DEFAULT_USER_DATA: UserPersistance = {
  uid: '',
  version: CURRENT_VERSION,
  currentProvider: null,
};

export function loadUserPersistance(uid?: string): UserPersistance {
  let data: any;
  const value = getCookie('user');
  if (value) {
    try {
      data = JSON.parse(value);
    } catch (e) {
      data = undefined;
    }
  }
  if (data && data.version === CURRENT_VERSION && data.uid === uid) {
    return data as UserPersistance;
  }
  return {
    uid: uid || '',
    version: CURRENT_VERSION,
    currentProvider: null,
  };
}

export function saveUserPersistance(persistance?: UserPersistance): UserPersistance {
  const data = { ...DEFAULT_USER_DATA, ...persistance };
  data.version = CURRENT_VERSION;
  setCookie('user', JSON.stringify(data));
  return data;
}
