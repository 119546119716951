/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import './styles.scss';

export const LogoOverlay: React.FC<Record<string, never>> = () => {
  return (
    <a id={'LogoOverlay'} href={'https://recursica.com'} className={'LogoOverlay'} aria-label={'Go to Recursica.com'}>
      <Logo />
    </a>
  );
};
