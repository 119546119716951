/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

function paramsToString(params?: any): string {
  if (!params) {
    return '';
  }
  let result = '';
  Object.keys(params).forEach((key, index) => {
    if (index > 0) {
      result += '&';
    }
    result += `${key}=${encodeURI(`${params[key]}`)}`;
  });
  return result;
}

export function fetch(url: string, init?: any): Promise<any> {
  let newUrl = (url || '') as string;
  const newInit = { ...init };
  newInit.headers = { ...newInit.headers };
  if (newInit.params) {
    if (newUrl.indexOf('?') === -1) {
      newUrl = `${newUrl}?`;
    } else {
      newUrl = `${newUrl}&`;
    }
    newUrl += paramsToString(newInit.params);
  }
  if (newInit.accessToken) {
    newInit.headers.Authorization = `Bearer ${newInit.accessToken}`;
  }
  if (!newInit.headers['Content-Type']) {
    newInit.headers['Content-Type'] = 'application/json';
  }
  if (newInit.contentType) {
    newInit.headers['Content-Type'] = newInit.contentType;
  }
  if (newInit.trace) {
    console.log('** FETCH: ', newUrl, newInit);
  }
  return window.fetch(newUrl, newInit).then((response) => {
    if (!newInit.ignoreError && (!response || !response.ok)) {
      console.error('** FETCH error: ', response);
      throw response;
    }
    return response;
  });
}

export function get<T>(url: string, init?: any): Promise<T> {
  return fetch(url, init)
    .then((response) => response.json())
    .then((result) => {
      return result as T;
    });
}

export function post<T>(url: string, body: any, init?: any): Promise<T> {
  if (!body || typeof body !== 'object') {
    throw new Error('Invalid non-object body');
  }
  const newOptions: any = { ...init };
  newOptions.method = 'POST';
  newOptions.body = JSON.stringify(body);
  return get<T>(url, newOptions);
}

export function put<T>(url: string, body: any, init?: any): Promise<T> {
  if (!body || typeof body !== 'object') {
    throw new Error('Invalid non-object body');
  }
  const newOptions: any = { ...init };
  newOptions.method = 'PUT';
  newOptions.body = JSON.stringify(body);
  return get<T>(url, newOptions);
}

export function del(url: string, init?: any): Promise<void> {
  const newOptions: any = { ...init };
  newOptions.method = 'DELETE';
  return get<void>(url, newOptions);
}
